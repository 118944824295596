import { merge } from 'lodash';
import EditorData from 'components/editor-data/EditorData';
import Templates from 'components/data/Templates';
import CustomerHelperLoader from 'components/data/CustomerHelperLoader';
import DataHelpers from 'components/data/DataHelpers';
import PublishHelpers from 'components/data/PublishHelpers';
import TemplateHelpers from 'components/data/TemplateHelpers';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import CreativeBuilder from './creative-builder';

/**
 * CreativeBuilderAssets
 * Builds individual stand alone assets
 */
export default class CreativeBuilderAssets {
    /**
     * Publish HTML5 zip
     * @param {*} uuid
     * @param {*} task
     * @param {*} data
     * @param {*} language
     * @param {*} subsetItem
     * @param {*} assetData
     * @param {*} prefix
     * @param {*} feedData
     */
    static async generateDisplayFormatsHTML5Task(uuid, setNr, task, data, language, subsetItem, subsetItemNr, assetData = false, prefix, feedData) {
        const settings = data.assetSetup.settings ? data.assetSetup.settings : {};
        const formatsOriginal = (() => {
            if (task.ignoreActiveFormats) {
                return data.assetSetup.settings ? data.assetSetup.settings.availableFormats : [];
            }

            return data.assetSetup.settings ? data.assetSetup.settings.formats : [];
        })();
        let newTasks = [];
        const outputHTML = {};
        const outputImages = {};
        const adData = assetData ? assetData : EditorData.getValueFromModel(task.dataModel + '.' + uuid + '.data.' + subsetItem + '.assetData');

        // Load template
        await Templates.asyncGetTemplatesItem(data.type, data.assetSetup.templateIdentifier);

        // Loop through tasks
        formatsOriginal.forEach((formatData) => {
            const format = formatData.format;
            let exportSettings = settings.exportSettings;

            // Merge overwrites
            let adDataFormat = DataHelpers.clone(adData);
            const overwrites = EditorData.getValueFromModel('overwrites.' + format, adData);
            if (overwrites) {
                adDataFormat = merge(adDataFormat, DataHelpers.clone(overwrites));
            }

            TemplateHelpers.removeGallery(adDataFormat, language);
            TemplateHelpers.removeFeedData(adDataFormat);

            // Ad format data
            adDataFormat.gallery = undefined;
            adDataFormat.overwrites = undefined;
            adDataFormat.publish = undefined;

            const adFormatDataTotal = {
                ...adDataFormat,
                language: language,
                market: EditorData.get('market'),
                brand: EditorData.get('brand'),
                format: format,
                frameNr: 0,
                editmode: 0
            };

            // Create task
            const uniqueName = PublishHelpers.creativeBuilderNaming({
                type: 'display',
                uuid,
                subsetItem,
                format,
                language,
                prefix
            });
            const filename = PublishHelpers.creativeBuilderNaming({
                type: 'display',
                nameType: 'filename',
                title: data.title,
                data,
                uuid,
                setNr,
                subsetItem,
                subsetItemNr,
                format,
                language,
                prefix,
                formatData,
                customNameTemplate: task.customNameTemplate
            });

            // Export as image
            if ((settings.exportType && settings.exportType === 'image') || (task.exportType && task.exportType === 'image')) {
                let extension = settings.extension ? settings.extension : 'jpg';
                if (task.extension) {
                    extension = task.extension;
                }

                const imageData = {
                    service: 'creatives',
                    type: 'HTMLToImage',
                    url: settings.url,
                    width: formatData.width,
                    height: formatData.height,
                    extension: extension,
                    name: format,
                    quality: task.quality,
                    applyCompression: task.applyCompression,
                    compressionLevel: task.compressionLevel,
                    hashStringifiedJSON: {
                        ...adDataFormat,
                        language: language,
                        market: EditorData.get('market'),
                        brand: EditorData.get('brand'),
                        format: format,
                        editmode: 0,
                        frameNr: data.frames ? data.frames : 1
                    },
                    resourceId: uniqueName + '-image',
                    resources: {
                        url_x2: uniqueName + '-image-x2'
                    }
                };

                newTasks.push(imageData);
                outputImages[filename + '.' + extension] = '{{' + uniqueName + '-image}}';

                if (!task.noRetinaSize) {
                    outputImages[filename + '_@x2.' + extension] = '{{' + uniqueName + '-image-x2}}';
                }

                DataHelpers.setModel(CreativeBuilder.assetStructure, uuid + '.' + format + '.' + subsetItem, '{{' + uniqueName + '-image}}');
            }
            // Export as GIF
            else if ((settings.exportType && settings.exportType === 'GIF') || (task.exportType && task.exportType === 'GIF')) {
                let encodedSrc = `${settings.url}/#${encodeURIComponent(JSON.stringify(adFormatDataTotal))}`;

                // if template is a template designer display ad or video ad then use the template data and template url
                if (['displayAdDesigned', 'dynamicVideoDesigned'].includes(data.type)) {
                    let template = Templates.get(data.type, data.assetSetup.templateIdentifier, 'templateExport');
                    // Check if template is still available
                    if (!template) {
                        SnackbarUtils.error('Template not found for item: ' + data.title);
                        return newTasks;
                    }

                    template = DataHelpers.clone(template);
                    const zipUrl = process.env.TEMPLATES_URL;
                    TemplateHelpers.removeTemplateFormatData(template, format, adFormatDataTotal);

                    // delete publish data (not needed for template creation)
                    delete adFormatDataTotal['publish'];

                    const templateData = {
                        model: adFormatDataTotal,
                        template: Templates.get(data.type, data.assetSetup.templateIdentifier, 'templateExport')
                    };

                    encodedSrc = `${zipUrl}#${encodeURIComponent(JSON.stringify(templateData))}`;
                }

                if (task.exportSettings) {
                    exportSettings = task.exportSettings;
                }

                const gifData = {
                    service: 'creatives',
                    type: 'HTMLToGIF',
                    htmlUrl: encodedSrc,
                    width: formatData.width,
                    height: formatData.height,
                    name: format,
                    waitTime: exportSettings.waitTime,
                    frameTime: exportSettings.frameTime,
                    frames: exportSettings.dynamicFrames ? data.frames : exportSettings.frames,
                    resourceId: uniqueName + '-gif',
                    quality: exportSettings.quality ? exportSettings.quality : null, // optional
                    repeat: exportSettings.repeat ? exportSettings.repeat : null, // optional
                    screenshotTime: exportSettings.screenshotTime ? exportSettings.screenshotTime : null, // optional
                    useSubstractionEncoding: exportSettings.useSubstractionEncoding ? exportSettings.useSubstractionEncoding : null, // optional
                    startOnClick: exportSettings.startOnClick ? exportSettings.startOnClick : null // optional
                };
                newTasks.push(gifData);
                outputImages[filename + '.gif'] = '{{' + uniqueName + '-gif}}';

                DataHelpers.setModel(CreativeBuilder.assetStructure, uuid + '.' + format + '.' + subsetItem, '{{' + uniqueName + '-gif}}');
            }
            // Regular HTML5
            else {
                // Get format data
                let zipUrl;

                let dataSet = {
                    ...adDataFormat,
                    language: language,
                    market: EditorData.get('market'),
                    brand: EditorData.get('brand'),
                    format: format,
                    frameNr: 0,
                    editmode: 0,
                    xandrTemplate: settings.xandrTemplate ? true : undefined,
                    studioTemplate: settings.studioTemplate ? true : undefined
                };

                let backupImageWaitTime = settings.backupImageWaitTime ? settings.backupImageWaitTime : undefined;

                // Add the template
                if (data.type === 'displayAdDesigned') {
                    let template = Templates.get(data.type, data.assetSetup.templateIdentifier, 'templateExport');

                    // Check if template is still available
                    if (!template) {
                        SnackbarUtils.error('Template not found for item: ' + data.title);
                        return newTasks;
                    }

                    template = DataHelpers.clone(template);
                    zipUrl = process.env.TEMPLATES_ZIP_URL;
                    TemplateHelpers.removeTemplateFormatData(template, format, dataSet);

                    // delete publish data (not needed for template creation)
                    delete dataSet['publish'];

                    dataSet = {
                        model: dataSet,
                        template: template
                    };

                    backupImageWaitTime = (() => {
                        if (template.backgroundbackupImageWaitTime === undefined) {
                            return undefined;
                        }

                        let duration = 0;

                        if (adDataFormat.frames) {
                            Object.keys(adDataFormat.frames).forEach((frameKey) => {
                                const frame = adDataFormat.frames[frameKey];
                                let frameType = frame.type;

                                if (frameType === 'none') {
                                    frameType = Object.keys(template.frames)[0];
                                }

                                duration += template.frames[frameType]?.duration || 0;
                            });
                        } else {
                            let frameType = adDataFormat.type;

                            if (frameType === 'none') {
                                frameType = Object.keys(template.frames)[0];
                            }

                            duration = template.frames[frameType]?.duration || 0;
                        }

                        const waitTime = duration * 1000 - template.backgroundbackupImageWaitTime;

                        return waitTime > 0 ? waitTime : 0;
                    })();
                }
                // Get format data
                else {
                    zipUrl = settings.url + '/display.zip';
                    if (settings.url.substr(-4, 4) === 'html') {
                        zipUrl = settings.url.substr(0, settings.url.length - 4) + 'display.zip';
                    }
                    if (settings.url.substr(-5, 5) === 'html/') {
                        zipUrl = settings.url.substr(0, settings.url.length - 5) + 'display.zip';
                    }
                }

                // Create display ads
                newTasks.push({
                    service: 'displayads',
                    type: 'html5',
                    template: zipUrl,
                    images: {},
                    data: dataSet,
                    backupImageGetParameters: {
                        frameNr: data.frames ? data.frames : 1
                    },
                    gif: {
                        include: task.gif ? true : false,
                        duration: task.gif?.duration,
                        totalFrames: data.frames,
                        loopCount: task.gif?.loopCount,
                        waitTime: backupImageWaitTime
                    },
                    useLottieLocal: task.useLottieLocal,
                    backupImageWaitTime: backupImageWaitTime,
                    width: formatData.width,
                    height: formatData.height,
                    htmlResourceId: uniqueName + '-html',
                    imageResourceId: uniqueName + '-image',
                    imageResourceId_x2: uniqueName + '-image-x2',
                    gifResourceId: uniqueName + '-gif',
                    xandrTemplate: settings.xandrTemplate ? true : undefined,
                    exportForPlatform: task.exportForPlatform ? task.exportForPlatform : settings.exportForPlatform,
                    studioTemplate: settings.studioTemplate ? true : undefined,
                    studioProfileId: settings.studioProfileId ? settings.studioProfileId : undefined
                });

                // Create output zip
                outputHTML[filename + '.zip'] = '{{' + uniqueName + '-html}}';
                outputImages[filename + '.jpg'] = '{{' + uniqueName + '-image}}';

                DataHelpers.setModel(CreativeBuilder.assetStructure, uuid + '.' + format + '.' + subsetItem + '.zip', '{{' + uniqueName + '-html}}');
                DataHelpers.setModel(CreativeBuilder.assetStructure, uuid + '.' + format + '.' + subsetItem + '.image', '{{' + uniqueName + '-image}}');

                if (task.gif) {
                    outputImages[filename + '.gif'] = '{{' + uniqueName + '-gif}}';
                    DataHelpers.setModel(CreativeBuilder.assetStructure, uuid + '.' + format + '.' + subsetItem + '.gif', '{{' + uniqueName + '-gif}}');
                }

                if (!task.noRetinaSize) {
                    outputImages[filename + '_@x2.jpg'] = '{{' + uniqueName + '-image-x2}}';
                }
            }
        });

        // Merge tasks (creation of zip, packing it and publishing it)
        if (!CreativeBuilder.files.html) {
            CreativeBuilder.files.html = {};
        }
        if (!CreativeBuilder.files.images) {
            CreativeBuilder.files.images = {};
        }

        CreativeBuilder.files.html = { ...CreativeBuilder.files.html, ...outputHTML };
        CreativeBuilder.files.images = { ...CreativeBuilder.files.images, ...outputImages };

        // Build using the feed data
        const customerHelper = new CustomerHelperLoader.helper();
        if (customerHelper.creativeBuilderAssetsHelper) {
            newTasks = customerHelper.creativeBuilderAssetsHelper(newTasks, feedData);
        }

        return newTasks;
    }

    /**
     * Publish html to video
     * @param {*} uuid
     * @param {*} task
     * @param {*} data
     * @param {*} language
     * @param {*} subsetItem
     * @param {*} assetData
     * @param {*} prefix
     * @param {*} feedData
     */
    static async createDynamicVideoDesigned(uuid, setNr, task, data, language, subsetItem, subsetItemNr, assetData = false, prefix) {
        const formatsOriginal = (() => {
            if (task.ignoreActiveFormats) {
                return data.assetSetup.settings ? data.assetSetup.settings.availableFormats : [];
            }

            return data.assetSetup.settings ? data.assetSetup.settings.formats : [];
        })();
        const newTasks = [];
        const newTasksPublish = [];
        const outputVideos = {};
        const adData = assetData ? assetData : EditorData.getValueFromModel(task.dataModel + '.' + uuid + '.data.' + subsetItem + '.assetData');

        // Load template
        await Templates.asyncGetTemplatesItem(data.type, data.assetSetup.templateIdentifier);

        // Loop through tasks
        formatsOriginal.forEach((formatData) => {
            // Merge overwrites
            const format = formatData.format;
            let adDataFormat = DataHelpers.clone(adData);
            const overwrites = EditorData.getValueFromModel('overwrites.' + format, adData);
            if (overwrites) {
                adDataFormat = merge(adDataFormat, DataHelpers.clone(overwrites));
            }
            TemplateHelpers.removeGallery(adDataFormat, language);
            TemplateHelpers.removeFeedData(adDataFormat);

            // Create task
            const uniqueName = PublishHelpers.creativeBuilderNaming({
                type: 'dynamicVideoDesigned',
                uuid,
                subsetItem,
                format,
                language,
                prefix
            });
            const filename = PublishHelpers.creativeBuilderNaming({
                type: 'dynamicVideoDesigned',
                nameType: 'filename',
                title: data.title,
                data,
                uuid,
                setNr,
                subsetItem,
                subsetItemNr,
                format,
                language,
                prefix,
                formatData,
                customNameTemplate: task.customNameTemplate
            });

            // Get format data
            let dataSet = {
                ...adDataFormat,
                language: language,
                market: EditorData.get('market'),
                brand: EditorData.get('brand'),
                format: format,
                frameNr: 0,
                editmode: 0
            };

            let template = Templates.get(data.type, data.assetSetup.templateIdentifier, 'templateExport');

            // Check if template is still available
            if (!template) {
                SnackbarUtils.error('Template not found for item: ' + data.title);
                return { newTasksAssets: newTasks, newTasksPublish };
            }

            template = DataHelpers.clone(template);
            TemplateHelpers.removeTemplateFormatData(template, format, dataSet);

            const templateUrl = process.env.TEMPLATES_ZIP_URL;
            dataSet = {
                model: dataSet,
                template: template
            };

            let duration = 0;
            for (const frameType in template.frames) {
                if (frameType !== 'base' || duration === 0 || frameType === 'main') {
                    duration = template.frames[frameType].duration;
                }
            }

            // Create display ads
            newTasks.push({
                service: 'creatives',
                type: 'HTMLToVideo',
                zipUrl: templateUrl,
                videoData: dataSet,
                duration: duration,
                fps: template && template.useHigherFps ? 30 : 25,
                seeds: task.multis || 2,
                width: formatData.width,
                height: formatData.height,
                resourceId: uniqueName + '_asset'
            });

            // Create output zip
            outputVideos[filename + '.mp4'] = '{{' + uniqueName + '_asset}}';
            DataHelpers.setModel(CreativeBuilder.assetStructure, uuid + '.' + format + '.' + subsetItem, '{{' + uniqueName + '_asset}}');

            // Loop through conversions for video format conversions
            if (task.conversions) {
                for (const i in task.conversions) {
                    const newTaskConverted = {
                        service: 'creatives',
                        type: 'convertVideo',
                        resourceId: uniqueName + '_convert' + i,
                        url: '{{' + uniqueName + '_asset}}',
                        ...task.conversions[i]
                    };
                    CreativeBuilder.files[filename + '_converted_' + (i + 1) + '.' + task.conversions[i].outputFormat] =
                        '{{' + uniqueName + '_convert' + i + '}}';
                    newTasksPublish.push(newTaskConverted);
                }
            }
        });
        CreativeBuilder.files.videos = { ...CreativeBuilder.files.videos, ...outputVideos };

        return { newTasksAssets: newTasks, newTasksPublish };
    }

    /**
     * Publish After Effects to video
     * @param {*} uuid
     * @param {*} task
     * @param {*} data
     * @param {*} language
     * @param {*} subsetItem
     * @param {*} assetData
     * @param {*} prefix
     * @param {*} feedData
     */
    static async createDynamicAfterEffects(uuid, setNr, task, data, language, subsetItem, subsetItemNr, assetData = false, prefix) {
        const formatsOriginal = (() => {
            if (task.ignoreActiveFormats) {
                return data.assetSetup.settings ? data.assetSetup.settings.availableFormats : [];
            }

            return data.assetSetup.settings ? data.assetSetup.settings.formats : [];
        })();
        let newTasks = [];
        const newTasksPublish = [];
        const outputVideos = {};
        const adData = assetData ? assetData : EditorData.getValueFromModel(task.dataModel + '.' + uuid + '.data.' + subsetItem + '.assetData');

        // Load template
        await Templates.asyncGetTemplatesItem(data.type, data.assetSetup.templateIdentifier);

        // Loop through tasks
        formatsOriginal.forEach((formatData) => {
            // Merge overwrites
            const format = formatData.format;
            let adDataFormat = DataHelpers.clone(adData);
            const overwrites = EditorData.getValueFromModel('overwrites.' + format, adData);
            if (overwrites) {
                adDataFormat = merge(adDataFormat, DataHelpers.clone(overwrites));
            }
            TemplateHelpers.removeGallery(adDataFormat, language);
            TemplateHelpers.removeFeedData(adDataFormat);

            // Create task
            const uniqueName = PublishHelpers.creativeBuilderNaming({
                type: 'dynamicAfterEffects',
                uuid,
                subsetItem,
                format,
                language,
                prefix
            });
            const filename = PublishHelpers.creativeBuilderNaming({
                type: 'dynamicAfterEffects',
                nameType: 'filename',
                title: data.title,
                data,
                uuid,
                setNr,
                subsetItem,
                subsetItemNr,
                format,
                language,
                prefix,
                formatData,
                customNameTemplate: task.customNameTemplate
            });

            let template = Templates.get(data.type, data.assetSetup.templateIdentifier);
            template = DataHelpers.clone(template);

            const aeTemplateData = TemplateHelpers.parseTdAfterEffects(
                {},
                {
                    template: {
                        type: 'dynamicAfterEffects',
                        identifier: template.templateSetup.templateSetup.adobe.identifier,
                        downloadUrl: template.templateSetup.templateSetup.adobe.downloadUrl,
                        layers: template.templateSetup.layers,
                        formats: template.templateSetup.formats,
                        interfaceSetup: template.interfaceSetup,
                        compositions: template.templateSetup.compositions
                    },
                    blockData: adDataFormat,
                    format
                },
                language
            );

            // Create display ads
            newTasks.push({
                service: 'aftereffectsrenderer',
                type: 'renderVideo',
                settings: aeTemplateData,
                resourceId: uniqueName + '_asset'
            });

            // Create output zip
            outputVideos[filename + '.mp4'] = '{{' + uniqueName + '_asset}}';
            DataHelpers.setModel(CreativeBuilder.assetStructure, uuid + '.' + format + '.' + subsetItem, '{{' + uniqueName + '_asset}}');

            // Loop through conversions for video format conversions
            if (task.conversions) {
                for (const i in task.conversions) {
                    const newTaskConverted = {
                        service: 'creatives',
                        type: 'convertVideo',
                        resourceId: uniqueName + '_convert' + i,
                        url: '{{' + uniqueName + '_asset}}',
                        ...task.conversions[i]
                    };
                    CreativeBuilder.files[filename + '_converted_' + (i + 1) + '.' + task.conversions[i].outputFormat] =
                        '{{' + uniqueName + '_convert' + i + '}}';
                    newTasksPublish.push(newTaskConverted);
                }
            }
        });
        CreativeBuilder.files.videos = { ...CreativeBuilder.files.videos, ...outputVideos };

        // Build using the feed data
        const customerHelper = new CustomerHelperLoader.helper();
        if (customerHelper.creativeBuilderAssetsHelper) {
            newTasks = customerHelper.creativeBuilderAssetsHelper(newTasks, []);
        }

        return { newTasksAssets: newTasks, newTasksPublish };
    }

    /**
     * Publish InDesign to pdf
     * @param {*} uuid
     * @param {*} task
     * @param {*} data
     * @param {*} language
     * @param {*} subsetItem
     * @param {*} assetData
     * @param {*} prefix
     * @param {*} feedData
     */
    static async createDynamicInDesign(uuid, setNr, task, data, language, subsetItem, subsetItemNr, assetData = false, prefix) {
        const formatsOriginal = (() => {
            if (task.ignoreActiveFormats) {
                return data.assetSetup.settings ? data.assetSetup.settings.availableFormats : [];
            }

            return data.assetSetup.settings ? data.assetSetup.settings.formats : [];
        })();
        let newTasks = [];
        const outputFiles = {};
        const adData = assetData ? assetData : EditorData.getValueFromModel(task.dataModel + '.' + uuid + '.data.' + subsetItem + '.assetData');

        // Load template
        await Templates.asyncGetTemplatesItem(data.type, data.assetSetup.templateIdentifier);

        // Loop through tasks
        formatsOriginal.forEach((formatData) => {
            // Merge overwrites
            const format = formatData.format;
            let adDataFormat = DataHelpers.clone(adData);
            const overwrites = EditorData.getValueFromModel('overwrites.' + format, adData);
            if (overwrites) {
                adDataFormat = merge(adDataFormat, DataHelpers.clone(overwrites));
            }
            TemplateHelpers.removeGallery(adDataFormat, language);

            // Create task
            const uniqueName = PublishHelpers.creativeBuilderNaming({
                type: 'dynamicInDesign',
                uuid,
                subsetItem,
                format,
                language,
                prefix
            });
            const filename = PublishHelpers.creativeBuilderNaming({
                type: 'dynamicInDesign',
                nameType: 'filename',
                title: data.title,
                data,
                uuid,
                setNr,
                subsetItem,
                subsetItemNr,
                format,
                language,
                prefix,
                formatData,
                customNameTemplate: task.customNameTemplate
            });

            let template = Templates.get(data.type, data.assetSetup.templateIdentifier);
            template = DataHelpers.clone(template);

            const idTemplateData = TemplateHelpers.parseTdInDesign(
                {},
                {
                    template: {
                        type: 'dynamicInDesign',
                        identifier: template.templateSetup.templateSetup.adobe.identifier,
                        downloadUrl: template.templateSetup.templateSetup.adobe.downloadUrl,
                        layers: template.templateSetup.layers,
                        interfaceSetup: template.interfaceSetup,
                        pages: template.templateSetup.pages
                    },
                    format: formatData.key,
                    blockData: adDataFormat,
                    preview: false
                },
                language
            );

            // Create display ads
            newTasks.push({
                service: 'indesign',
                type: 'export',
                settings: idTemplateData,
                resourceId: uniqueName + '-pdf',
                imageResourceId: uniqueName + '-jpg'
            });

            // Create output zip
            outputFiles[filename + '.pdf'] = '{{' + uniqueName + '-pdf}}';
            DataHelpers.setModel(CreativeBuilder.assetStructure, uuid + '.' + format + '.' + subsetItem, '{{' + uniqueName + '-pdf}}');
        });

        if (!CreativeBuilder.files.pdf) {
            CreativeBuilder.files.pdf = {};
        }

        CreativeBuilder.files.pdf = { ...CreativeBuilder.files.pdf, ...outputFiles };

        // Build using the feed data
        const customerHelper = new CustomerHelperLoader.helper();
        if (customerHelper.creativeBuilderAssetsHelper) {
            newTasks = customerHelper.creativeBuilderAssetsHelper(newTasks, []);
        }

        return newTasks;
    }

    /**
     * Creative dynamic image
     * @param {*} uuid
     * @param {*} task
     * @param {*} data
     * @param {*} language
     * @param {*} subsetItem
     * @param {*} prefix
     * @param {*} feedData
     */
    static async createDynamicImage(uuid, setNr, task, data, language, subsetItem, subsetItemNr, prefix) {
        const formatsOriginal = (() => {
            if (task.ignoreActiveFormats) {
                return data.assetSetup.settings ? data.assetSetup.settings.availableFormats : [];
            }

            return data.assetSetup.settings ? data.assetSetup.settings.formats : [];
        })();
        let newTasks = [];
        const outputImages = {};
        const adData = EditorData.getValueFromModel(task.dataModel + '.' + uuid + '.data.' + subsetItem + '.assetData');

        // Load template
        await Templates.asyncGetTemplatesItem(data.type, data.assetSetup.templateIdentifier);

        // Loop through tasks
        formatsOriginal.forEach((formatData) => {
            const format = formatData.format;

            // Merge overwrites
            let adDataFormat = DataHelpers.clone(adData);
            const overwrites = EditorData.getValueFromModel(task.dataModel + '.' + uuid + '.data.' + subsetItem + '.assetData.overwrites.' + format);
            if (overwrites) {
                adDataFormat = merge(adDataFormat, DataHelpers.clone(overwrites));
            }
            TemplateHelpers.removeGallery(adDataFormat, language);
            TemplateHelpers.removeFeedData(adDataFormat);

            // Create task
            const uniqueName = PublishHelpers.creativeBuilderNaming({
                type: 'dynamicImage',
                uuid,
                subsetItem,
                format,
                language,
                prefix
            });
            const filename = PublishHelpers.creativeBuilderNaming({
                type: 'dynamicImage',
                nameType: 'filename',
                title: data.title,
                data,
                uuid,
                setNr,
                subsetItem,
                subsetItemNr,
                format,
                language,
                prefix,
                formatData,
                customNameTemplate: task.customNameTemplate
            });

            // Create image
            const extension = task.extension ? task.extension : 'jpg';
            let dataSet = {
                ...adDataFormat,
                language: language,
                market: EditorData.get('market'),
                brand: EditorData.get('brand'),
                format: format,
                frameNr: 0,
                editmode: 0
            };

            let url = data.assetSetup.previewUrl;

            // Add the template
            if (data.type === 'dynamicImageDesigned') {
                let template = Templates.get(data.type, data.assetSetup.templateIdentifier, 'templateExport');

                // Check if template is still available
                if (!template) {
                    SnackbarUtils.error('Template not found for item: ' + data.title);
                    return newTasks;
                }

                template = DataHelpers.clone(template);
                TemplateHelpers.removeTemplateFormatData(template, format, dataSet);

                url = process.env.TEMPLATES_URL;
                dataSet = {
                    model: dataSet,
                    template: template
                };
            }

            newTasks.push({
                service: 'creatives',
                type: 'HTMLToImage',
                url: url,
                width: formatData.width,
                height: formatData.height,
                extension: extension,
                name: format,
                hashStringifiedJSON: dataSet,
                quality: task.quality,
                applyCompression: task.applyCompression,
                compressionLevel: task.compressionLevel,
                includeRetinaSize: !task.noRetinaSize,
                resourceId: uniqueName + '-image',
                resources: {
                    url_x2: uniqueName + '-image-x2'
                }
            });

            // Create output zip
            outputImages[filename + '.' + extension] = '{{' + uniqueName + '-image}}';
            DataHelpers.setModel(CreativeBuilder.assetStructure, uuid + '.' + format + '.' + subsetItem, '{{' + uniqueName + '-image}}');

            if (!task.noRetinaSize) {
                outputImages[filename + '-x2.' + extension] = '{{' + uniqueName + '-image-x2}}';
            }
        });

        if (!CreativeBuilder.files.images) {
            CreativeBuilder.files.images = {};
        }

        // Check custom helper
        const customerHelper = new CustomerHelperLoader.helper();
        if (customerHelper.creativeBuilderAssetsHelper) {
            newTasks = customerHelper.creativeBuilderAssetsHelper(newTasks, []);
        }

        CreativeBuilder.files.images = { ...CreativeBuilder.files.images, ...outputImages };
        return newTasks;
    }

    /**
     * Create dynamic PDF
     * @param {*} uuid
     * @param {*} task
     * @param {*} data
     * @param {*} language
     * @param {*} subsetItem
     * @param {*} prefix
     * @param {*} feedData
     */
    static async createDynamicPDF(uuid, setNr, task, data, language, subsetItem, subsetItemNr, prefix) {
        const settings = data.assetSetup.settings ? data.assetSetup.settings : {};
        const formatsOriginal = (() => {
            if (task.ignoreActiveFormats) {
                return data.assetSetup.settings ? data.assetSetup.settings.availableFormats : [];
            }

            return data.assetSetup.settings ? data.assetSetup.settings.formats : [];
        })();

        const newTasksAssets = [];
        const newTasksPublish = [];
        const outputFiles = {};
        const assetData = EditorData.getValueFromModel(task.dataModel + '.' + uuid + '.data.' + subsetItem + '.assetData');

        // Load template
        await Templates.asyncGetTemplatesItem(data.type, data.assetSetup.templateIdentifier);

        // Loop through tasks
        formatsOriginal.forEach((formatData) => {
            const format = formatData.format;

            // Merge overwrites
            let itemFormatData = DataHelpers.clone(assetData);
            const overwrites = EditorData.getValueFromModel(task.dataModel + '.' + uuid + '.data.' + subsetItem + '.assetData.overwrites.' + format);
            if (overwrites) {
                itemFormatData = merge(itemFormatData, DataHelpers.clone(overwrites));
            }
            // Create task
            const uniqueName = PublishHelpers.creativeBuilderNaming({
                type: 'dynamicPDF',
                uuid,
                subsetItem,
                format,
                language,
                prefix
            });
            const filename = PublishHelpers.creativeBuilderNaming({
                type: 'dynamicPDF',
                nameType: 'filename',
                title: data.title,
                data,
                uuid,
                setNr,
                subsetItem,
                subsetItemNr,
                format,
                language,
                prefix,
                formatData,
                customNameTemplate: task.customNameTemplate
            });

            if (settings.dynamicFrames) {
                const pdfMerge = [];

                for (let frameNr = 1; frameNr <= data.frames; frameNr++) {
                    let dataSet = {
                        ...itemFormatData,
                        language: language,
                        market: EditorData.get('market'),
                        brand: EditorData.get('brand'),
                        format: format,
                        frameNr: 0,
                        editmode: 0,
                        printSize: true
                    };

                    let url = data.assetSetup.previewUrl;
                    let useCMYK = data.assetSetup.useCMYK || task.useCMYK || false;
                    let withCuttingLines = data.assetSetup.withCuttingLines || task.withCuttingLines || false;
                    const dpi = data.assetSetup.dpi || task.dpi;
                    const waitTime = data.assetSetup.waitTime || task.waitTime;

                    // Add the template
                    if (data.type === 'dynamicPDFDesigned') {
                        let template = Templates.get(data.type, data.assetSetup.templateIdentifier, 'templateExport');
                        template = DataHelpers.clone(template);
                        url = process.env.TEMPLATES_URL;
                        dataSet = {
                            model: dataSet,
                            template: template
                        };

                        // overwrite useCMYK setting from the template if the template type is dynamicPDFDesigned
                        useCMYK = template.useCMYK ? true : useCMYK;
                        withCuttingLines = template.withCuttingLines ? true : withCuttingLines;
                    }

                    // Create image
                    newTasksAssets.push({
                        service: 'creatives',
                        type: 'HTMLToPDF',
                        isBoosted: task.isBoosted || false,
                        url: url,
                        width: formatData.width_full || formatData.width,
                        height: formatData.height_full || formatData.height,
                        cmyk: useCMYK,
                        dpi: dpi,
                        waitTime: waitTime,
                        withCuttingLines: withCuttingLines,
                        version: task.version || 1,
                        name: format,
                        hashStringifiedJSON: dataSet,
                        resourceId: uniqueName + '-pdf-page' + frameNr
                    });

                    // Create output zip
                    outputFiles[filename + '-page' + frameNr + '.pdf'] = '{{' + uniqueName + '-pdf-page' + frameNr + '}}';

                    pdfMerge.push({
                        url: '{{' + uniqueName + '-pdf-page' + frameNr + '}}',
                        pages: []
                    });
                }
                // Create image
                newTasksPublish.push({
                    service: 'creatives',
                    type: 'mergePDF',
                    pdf: pdfMerge,
                    resourceId: uniqueName + '-pdf'
                });
                outputFiles[filename + '.pdf'] = '{{' + uniqueName + '-pdf}}';
                DataHelpers.setModel(CreativeBuilder.assetStructure, uuid + '.' + format + '.' + subsetItem, '{{' + uniqueName + '-pdf}}');
            } else {
                let dataSet = {
                    ...itemFormatData,
                    language: language,
                    market: EditorData.get('market'),
                    brand: EditorData.get('brand'),
                    format: format,
                    frameNr: 0,
                    editmode: 0,
                    printSize: true
                };

                let url = data.assetSetup.previewUrl;
                let useCMYK = data.assetSetup.useCMYK || task.useCMYK || false;
                let withCuttingLines = data.assetSetup.withCuttingLines || task.withCuttingLines || false;
                const dpi = data.assetSetup.dpi || task.dpi;
                const waitTime = data.assetSetup.waitTime || task.waitTime;

                // Add the template
                if (data.type === 'dynamicPDFDesigned') {
                    let template = Templates.get(data.type, data.assetSetup.templateIdentifier, 'templateExport');

                    // Check if template is still available
                    if (!template) {
                        SnackbarUtils.error('Template not found for item: ' + data.title);
                        return { newTasksAssets, newTasksPublish };
                    }

                    template = DataHelpers.clone(template);
                    url = process.env.TEMPLATES_URL;
                    dataSet = {
                        model: dataSet,
                        template: template
                    };

                    // overwrite useCMYK setting from the template if the template type is dynamicPDFDesigned
                    useCMYK = template.useCMYK ? true : useCMYK;
                    withCuttingLines = template.withCuttingLines ? true : withCuttingLines;
                }

                // Create PDF
                newTasksAssets.push({
                    service: 'creatives',
                    type: 'HTMLToPDF',
                    isBoosted: task.isBoosted || false,
                    url: url,
                    width: formatData.width_full || formatData.width,
                    height: formatData.height_full ? formatData.height_full : formatData.height,
                    cmyk: useCMYK,
                    dpi: dpi,
                    waitTime: waitTime,
                    withCuttingLines: withCuttingLines,
                    version: task.version || 1,
                    name: format,
                    hashStringifiedJSON: dataSet,
                    resourceId: uniqueName + '-pdf'
                });
                // Create output zip
                outputFiles[filename + '.pdf'] = '{{' + uniqueName + '-pdf}}';
                DataHelpers.setModel(CreativeBuilder.assetStructure, uuid + '.' + format + '.' + subsetItem, '{{' + uniqueName + '-pdf}}');
            }
        });

        if (!CreativeBuilder.files.pdf) {
            CreativeBuilder.files.pdf = {};
        }

        CreativeBuilder.files.pdf = { ...CreativeBuilder.files.pdf, ...outputFiles };

        return { newTasksAssets, newTasksPublish };
    }

    /**
     * Set exported display ads
     * @param {*} uuid
     * @param {*} task
     * @param {*} data
     * @param {*} language
     * @param {*} subsetItem
     * @param {*} prefix
     * @param {*} feedData
     */
    static createStaticAssetDisplay(uuid, task, data, language, subsetItem) {
        const newTasksAssets = [];
        const newTasksPublish = [];
        const adData = EditorData.getValueFromModel(task.dataModel + '.' + uuid + '.data.' + subsetItem + '.assetData');

        if (!CreativeBuilder.files.html) {
            CreativeBuilder.files.html = {};
        }

        for (const i in adData.items) {
            const item = adData.items[i];
            const filename = item.uuid + '_' + item.width + 'x' + item.height + '.zip';
            CreativeBuilder.files.html[filename] = item.originalUrl;
        }

        return { newTasksAssets, newTasksPublish };
    }

    /**
     * Export images
     * @param {*} uuid
     * @param {*} task
     * @param {*} data
     * @param {*} language
     * @param {*} subsetItem
     * @param {*} prefix
     * @param {*} feedData
     */
    static createStaticAssetImage(uuid, task, data, language, subsetItem) {
        const newTasksAssets = [];
        const newTasksPublish = [];
        const adData = EditorData.getValueFromModel(task.dataModel + '.' + uuid + '.data.' + subsetItem + '.assetData');

        if (!CreativeBuilder.files.images) {
            CreativeBuilder.files.images = {};
        }

        for (const i in adData.items) {
            const item = adData.items[i];
            const filename = item.uuid + '_' + item.width + 'x' + item.height + '.' + item.extension;
            CreativeBuilder.files.images[filename] = item.url;
        }

        return { newTasksAssets, newTasksPublish };
    }

    /**
     * Export pdf
     * @param {*} uuid
     * @param {*} task
     * @param {*} data
     * @param {*} language
     * @param {*} subsetItem
     * @param {*} prefix
     * @param {*} feedData
     */
    static createStaticAssetPDF(uuid, task, data, language, subsetItem) {
        const newTasksAssets = [];
        const newTasksPublish = [];
        const adData = EditorData.getValueFromModel(task.dataModel + '.' + uuid + '.data.' + subsetItem + '.assetData');

        if (!CreativeBuilder.files.pdf) {
            CreativeBuilder.files.pdf = {};
        }

        for (const i in adData.items) {
            const item = adData.items[i];
            const filename = item.uuid + '_' + i + '.pdf';
            CreativeBuilder.files.pdf[filename] = item.url;
        }

        return { newTasksAssets, newTasksPublish };
    }

    /**
     * Render a video for all selected tasks
     * @param {*} uuid
     * @param {*} task
     * @param {*} data
     * @param {*} language
     * @param {*} subsetItem
     * @param {*} prefix
     * @param {*} feedData
     */
    static async renderVideoTask(uuid, setNr, task, data, language, subsetItem, subsetItemNr, prefix) {
        // Map the formats
        const formatsOriginal = (() => {
            if (task.ignoreActiveFormats) {
                return data.assetSetup.settings ? data.assetSetup.settings.availableFormats : [];
            }

            return data.assetSetup.settings ? data.assetSetup.settings.formats : [];
        })();
        let formats = {};
        if (task.channelMapping) {
            task.channelMapping.forEach((channelItem) => {
                channelItem.format.forEach((formatItem) => {
                    formats[formatItem] = formatsOriginal[formatItem];
                });
            });
        } else {
            formats = DataHelpers.clone(formatsOriginal);
        }

        // Get data
        const newTasks = [];
        const newTasksPublish = [];

        // Load template
        const template = await Templates.asyncGetTemplatesItem(data.type, data.assetSetup.templateIdentifier);

        Object.keys(formats).forEach((formatKey) => {
            const format = formats[formatKey];
            const dataModel = task.dataModel + '.' + uuid + '.data.' + subsetItem + '.assetData';
            let comp = template.publishMapping.comp;
            if (format && format.comp) {
                comp = format.comp;
            } else if (format && format.format) {
                comp = format.format;
            }
            if (format.format) {
                formatKey = format.format;
            }

            const uniqueName = PublishHelpers.creativeBuilderNaming({
                type: 'dynamicVideo',
                nameType: 'unique',
                uuid,
                subsetItem,
                format: format.format,
                language,
                prefix
            });
            const filename = PublishHelpers.creativeBuilderNaming({
                type: 'dynamicVideo',
                nameType: 'filename',
                title: data.title,
                data,
                uuid,
                setNr,
                subsetItem,
                subsetItemNr,
                format: comp,
                language,
                prefix,
                formatData: format,
                customNameTemplate: task.customNameTemplate
            });

            // Create mapping
            let affectivityNew;
            if (template.publishMapping && dataModel) {
                affectivityNew = DataHelpers.clone(template.publishMapping);
                affectivityNew.comp = comp;

                // Overwrites
                let videoData = DataHelpers.clone(EditorData.getValueFromModel(dataModel));
                const overwrites = EditorData.getValueFromModel(dataModel + '.overwrites.' + format.format);
                if (overwrites) {
                    videoData = merge(videoData, DataHelpers.clone(overwrites));
                }

                // Merge the data
                EditorData.parseDataDeep(affectivityNew, {
                    blockModel: dataModel,
                    blockData: videoData,
                    comp: comp,
                    language: language
                });
            }

            // Create task
            const newTask = {
                service: 'affectivity',
                type: 'renderVideo',
                projectName: affectivityNew.projectName,
                settings: affectivityNew,
                resourceId: uniqueName,
                imageResourceId: uniqueName + '-image'
            };

            CreativeBuilder.files[filename + '.mp4'] = '{{' + uniqueName + '}}';
            CreativeBuilder.files[filename + '.jpg'] = '{{' + uniqueName + '-image}}';
            DataHelpers.setModel(CreativeBuilder.assetStructure, uuid + '.' + formatKey + '.' + subsetItem + '.image', '{{' + uniqueName + '-image}}');
            DataHelpers.setModel(CreativeBuilder.assetStructure, uuid + '.' + formatKey + '.' + subsetItem + '.video', '{{' + uniqueName + '}}');

            newTasks.push(newTask);

            // Loop through conversions for video format conversions
            if (task.conversions) {
                for (const i in task.conversions) {
                    const newTaskConverted = {
                        service: 'creatives',
                        type: 'convertVideo',
                        resourceId: uniqueName + '_convert' + i,
                        url: '{{' + uniqueName + '}}',
                        ...task.conversions[i]
                    };
                    CreativeBuilder.files[filename + '_converted_' + (i + 1) + '.' + task.conversions[i].outputFormat] =
                        '{{' + uniqueName + '_convert' + i + '}}';
                    newTasksPublish.push(newTaskConverted);
                }
            }
        });

        return { newTasksAssets: newTasks, newTasksPublish };
    }
}
